<div class="container">
  <app-progress-spinner class="example-margin" [color]="color" [mode]="mode" [value]="value" [backdropEnabled]="true"
    [displayProgressSpinner]="spinnerWithoutBackdrop">
  </app-progress-spinner>
  <div id="top" fxLayout.xs="column" fxLayout="row" fxLayoutAlign="start start">
    <a href="https://tabocottages.com/">
      <div id="logo"></div>
    </a>
    <div id="menu"><a href="https://tabocottages.com/">Home</a></div>
  </div>
  <div id="background"></div>
  <div id="form">
    <h1>Tabocottages Reservation</h1>
    <form [formGroup]="orderForm" class="container-form">
      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" class="input-full-width">
          <span matPrefix>
            <mat-icon aria-hidden="false" style="color:rgb(52, 64, 235)">perm_identity</mat-icon>
          </span>

          <mat-label>Full Name </mat-label>
          <input matInput type="text" placeholder="Full Name" formControlName="namaControl" class="input-full-width">
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" class="input-full-width">
          <span matPrefix>
            <mat-icon aria-hidden="false" style="color:rgb(52, 64, 235)">mail_outline</mat-icon>
          </span>
          <mat-label>Email </mat-label>
          <input matInput type="text" placeholder="Email ex: johndoe@email.com" formControlName="emailControl"
            class="input-full-width">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" class="input-full-width">
        <mat-form-field appearance="outline" style="width: 40%;margin-right: 5%">
          <span matPrefix>
            <mat-icon aria-hidden="false" style="color:rgb(52, 235, 128)">smartphone</mat-icon>
          </span>
          <mat-label>Int Code </mat-label>
          <mat-select formControlName="codeControl" name="code">
            <mat-option *ngFor="let pc of phoneCode; let i = index" [value]="pc.code">
              {{pc.name}} {{pc.code}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" style="width: 55%;">
          <mat-label>Phone number, (Int. format, ex: 628xxx xxxx), </mat-label>
          <input matInput type="number" placeholder="Phone number" formControlName="phoneControl"
            class="input-full-width">
        </mat-form-field>
      </div>
      <p style="margin-top:-10px;text-align:left; margin-bottom:20px;font-size: 12px;">*Whatsapp number prefered</p>


      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" class="input-full-width">
          <mat-label>Check In - Check Out</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [min]="minDate">

            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker" style="color:rgb(52, 64, 235)">
          </mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" class="input-full-width">
        <mat-form-field appearance="outline" style="width: 47%;margin-right: 5%">
          <span matPrefix>
            <mat-icon aria-hidden="false" style="color:rgb(52, 64, 235)">face</mat-icon>
          </span>
          <mat-label>Adult</mat-label>
          <input matInput type="number" maxlength="2" placeholder="Adult " formControlName="adultControl">
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" class="input-full-width">
        <mat-form-field appearance="outline" style="width: 47%;margin-right: 5%">
          <span matPrefix>
            <mat-icon aria-hidden="false" style="color:rgb(52, 64, 235)">child_care</mat-icon>
          </span>
          <mat-label>0 - 5 Years</mat-label>
          <input matInput type="number" maxlength="2" placeholder="0 - 5 Years " formControlName="childrenControl">
        </mat-form-field>

        <mat-form-field appearance="outline" style="width: 47%;">
          <mat-label>6 - 12 Years</mat-label>
          <span matPrefix>
            <mat-icon aria-hidden="false" style="color:rgb(52, 64, 235)">child_care</mat-icon>
          </span>
          <input matInput type="number" maxlength="2" placeholder="6 - 12 Years " formControlName="childrenControl2">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" class="input-full-width">
        <mat-form-field appearance="outline" style="width: 100%;">
          <!-- <span matPrefix>
            <mat-icon aria-hidden="false" style="color:rgb(52, 64, 235)">sticky_note_2</mat-icon>
          </span> -->
          <mat-label>Special Request</mat-label>
          <textarea matInput type="number" placeholder="Special Request " rows="5"
            formControlName="specialControl"></textarea>
        </mat-form-field>
      </div>

      <!-- choose Room-->
      <div fxLayout="row" fxLayoutAlign="start start" style="text-align: left;">
        <mat-label style="font-size: 18px;">Choose Room </mat-label>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" class="input-full-width">

        <mat-form-field appearance="outline" style="width: 37%;margin-right: 5%;">
          <!-- <span matPrefix>
            <mat-icon aria-hidden="false" style="color:rgb(52, 235, 128)">smartphone</mat-icon>
          </span> -->
          <mat-label>Room Type</mat-label>
          <mat-select formControlName="typeControl" name="type">
            <mat-option *ngFor="let room of listRoom; let i = index" [value]="i">
              {{room.jns_kamar}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" style="width: 37%;">
          <!-- <span matPrefix>
            <mat-icon aria-hidden="false" style="color:rgb(52, 235, 128)">calculate</mat-icon>
          </span> -->
          <mat-label>Quantity</mat-label>
          <input matInput type="number" maxlength="2" placeholder="Quantity" formControlName="quantityControl"
            style="width: 100%;">
        </mat-form-field>

        <mat-icon (click)="addRoom()" *ngIf="orderForm.value.quantityControl !=='' && orderForm.value.typeControl !==''"
          style="color:black;margin-left: 10px;size: 30px;width:16%">add_circle_outline</mat-icon>
        <mat-icon *ngIf="orderForm.value.quantityControl ===''|| orderForm.value.typeControl ===''"
          style="color:grey;margin-left: 10px;size: 30px;width:16%">add_circle_outline</mat-icon>

      </div>

      <!-- end choose ROom-->
      <div *ngIf="roomType.length >0"
        style=" border:1px solid grey; border-radius: 15px; padding: 15px 15px 30px 15px;">
        <div fxLayout="row" *ngIf="roomType.length >0" fxLayoutAlign="start start" style="text-align: left;">
          <mat-label style="font-size: 18px;width:100%;float:left">Room Ordered </mat-label>
          <div style=" width:38%;float:left;text-align: left;margin-top: 15px;"><b>Room Type</b></div>
          <div style="width:10%;float:left;text-align: left;margin-top: 15px;"><b>Qty</b></div>
          <div style="width:12%;float:left;text-align: left;margin-top: 15px;"><b>Night(s)</b></div>
          <div style=" width:25%;float:left;text-align: left;margin-top: 15px;"><b>Sub Total</b></div>

          <hr style="width: 100%" />
        </div>
        <div *ngFor="let rm of roomType;let a = index" fxLayoutAlign="start start" fxFlex="100%"
          style="margin-top: 15px;margin-bottom:15px;">

          <div style=" width:38%;float:left;text-align: left;"><b>{{rm.jns_kamar}}</b></div>
          <div style="width:10%;float:left;text-align: left;"><b>{{rm.jumlah}}</b></div>
          <div style="width:12%;float:left;text-align: left;"><b>{{rm.lamaMenginap}}</b></div>
          <div style=" width:25%;float:left;text-align: left;"><b>{{rm.total | currency:0:''}}</b></div>
          <div style=" width:10%;float:left;text-align: left;">
            <mat-icon (click)="remove(a)" style="color:red;margin-left:15px;size: 30px;">cancel_outline</mat-icon>
          </div>
          <div style=" width:25%;float:inherit;color: grey;">Facilities :</div>
          <div *ngFor="let fc of rm.fasilitas; let i=index" style="text-align: left;width:85%;margin-left: 50px;">
            <b>{{i+1}}. {{fc}}</b>
          </div>
          <hr style="width: 100%;border:0.5px solid rgb(194, 189, 189)" />
        </div>
        <div fxLayout="row" *ngIf="roomType.length >0" fxLayoutAlign="start start" style="text-align: left;">
          <mat-label style="font-size: 18px;width:calc(40% - 15px);float:left;text-align: right; margin-right: 15px;">
            Total
          </mat-label>
          <div style="width:20%;float:left;text-align: left;"><b>{{totalRoom}} Rooms</b></div>
          <div style="width:30%;float:left;text-align: left;"><b>{{total | currency:0:'Rp '}}</b></div>
        </div>
      </div>

    </form>
    <div fxLayout="row" fxLayoutAlign="end start" style="margin-top: 15px;">
      <button mat-stroked-button color="accent" matTooltip="Reserv" mat-button class="save-button" aria-label="SAVE"
        color="accent" cdkFocusInitial (click)="reserv()" [disabled]="!orderForm.valid || roomType.length ===0">
        RESERVATION
      </button>
    </div>
  </div>
</div>