import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Page, ReservasiModel, RoomModel } from './model';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  BaseURL = environment.BaseUrl;
  constructor(private _http: HttpClient) { }
  httpheader = new HttpHeaders({
    'Content-Type': 'application/json',
    "Authorization": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6Im1haGVzYTIwMTIxMyJ9.N_tThkaOD-E0z_4rzvIcBD2qhxlh0N5g5uWXXFH_o5g",
  });

  httpheader2 = new HttpHeaders({
    'Accept': 'text/html, application/xhtml+xml, */*',
    'Content-Type': 'application/x-www-form-urlencoded',
    // 'Content-Type': 'application/json',
    "Authorization": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6Im1haGVzYTIwMTIxMyJ9.N_tThkaOD-E0z_4rzvIcBD2qhxlh0N5g5uWXXFH_o5g",
  });

  optionsGet = {
    headers: this.httpheader
  };

  optionPost = {
    headers: this.httpheader2
  }
  optionPostNoAuth = {
    headers: this.httpheader
  }

  ambilData(): Observable<Page<ReservasiModel[]>> {
    return this._http.get<Page<ReservasiModel[]>>(`${this.BaseURL}api/reservasi/`, this.optionsGet)
      .pipe(
        catchError(this.errorHandl)
      )
  }

  ambilRoom(): Observable<Page<RoomModel[]>> {
    return this._http.get<Page<RoomModel[]>>(`${this.BaseURL}api/ambilType/`, this.optionsGet)
      .pipe(
        catchError(this.errorHandl)
      )
  }

  simpanReservasi(data: ReservasiModel): Observable<Page<any>> {
    return this._http.post<Page<any>>(`${this.BaseURL}api/reservSave/`, JSON.stringify(data), this.optionPost)
      .pipe(
        catchError(this.errorHandl)
      )
  }

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
